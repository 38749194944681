.block-menu-block {
  text-transform: uppercase;
  h2 {
    display: block;
    font-size: 1.1em;
    padding: 10px 0;
    border-bottom: solid 2px $primary;
    font-weight: 400;
    margin-bottom: 0;
  }
  .menu-block-wrapper {
    ul {
      display: block;
      position: relative;
      border: none;
      padding: 0;
      margin: 0;
      li {
        padding: 4px 0;
        border-bottom: solid 1px $gray-200;
        a {
          padding: 6px 10px;
          display: block;
          color: $black;
          font-size: 0.95em;
          &:hover {
            background-color: $primary;
            color: $white;
          }
        }
        ul {
          border-top: solid 1px $gray-200;
          li {
            a {
              padding-left: 25px;
            }
          }
          ul {
            li {
              a {
                padding-left: 45px;
              }
            }
          }
        }
      }
    }
  }
  .menu-collapse-control {
    display: none;
  }
}