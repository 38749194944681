// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge(
                (
                        xs: 0px,
                        xsc: 540px,
                        sm: 768px,
                        md: 992px,
                        lg: 1200px,
                        xl: 1600px
                ),
                $grid-breakpoints
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge(
                (
                        xsc: 500px,
                        sm: 720px,
                        md: 960px,
                        lg: 1140px,
                        xl: 1440px
                ),
                $container-max-widths
);

$spacer: 1rem !default;

// Changing the body background and text
$body-color: #3c3c3b;

// Changing the border radius of buttons
$border-radius: 0;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:                   900 !default;
$zindex-sticky:                     920 !default;
$zindex-fixed:                      930 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;


// theme colors
$black:     #1c2024;

$headings-color: $primary;

$font-family-sans-serif: 'Titillium Web', sans-serif;

$font-size-base:              rem(18px); // Assumes the browser default, typically `16px`
$line-height-base:            1.5;

$navbar-dark-color:           #FFFFFF;
$navbar-dark-hover-color:     #FFFFFF;


$font-weight-base:            400;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.25 !default;
$h2-font-size:                $font-size-base * 1.8 !default;
$h3-font-size:                $font-size-base * 1.6 !default;
$h4-font-size:                $font-size-base * 1.4 !default;
$h5-font-size:                $font-size-base * 1.2 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.3 !default;
$headings-color:              $black;

$link-hover-color:                        $primary;
$link-hover-decoration:                   underline !default;