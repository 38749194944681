#footer {
  background-color: $gray-800;
  color: $white;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 38px;
  a {
    color: $white;
  }
  .region-bottom {
    background-color: $gray-900;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
    line-height: 38px;
  }
}
.region-footer-top {
  border-top: solid 1px $gray-200;
  padding: 10px;
  margin: 0 20px;
}