.logo-univpm {
  fill: $white;
}
.bg-primary {
  a {
    color: $white;
  }
}
.section-utility {
  background: $gray-800;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 2px solid $primary_dark;
}
.bg-primary-dark {
  background: $primary_dark;
}
button:focus {
  outline: none;
}