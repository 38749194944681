.site-search {
  .block-search {
    .container-inline {
      position: relative;
      input {
        &[type=text]{
          height: 20px;
          border-radius: 2px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .form-actions {
        height: 0;
        line-height: 0;
      }
      .btn-search {
        position: absolute;
        top:0;
        right: 0;
        border: none;
        background: transparent;
        padding: 0;
        width: 20px;
        height: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}