.navbar-brand {
  margin-right: 0;
  svg {
    max-width: 200px;
    height: auto;
    @include media-breakpoint-up(sm) {
      max-width: 320px;
    }
  }
  @include media-breakpoint-up(xsc){
    display: flex;
    width: calc(100% - 80px);
    justify-content: space-between;
    align-items: center;
  }
}
.header-dipartimento {
  white-space: normal;
  margin-top:10px;
  .nome-dipartimento {
    font-size: 16px;
    line-height: 1.2em;
    max-width: 200px;
    float: left;
    white-space: normal;
    @include media-breakpoint-up(xsc){
      clear: both;
      display: block;
      line-height: 1.1em;
    }
  }
  @include media-breakpoint-down(sm){
    .logo-dipartimento {
      display: none;
      max-width: 200px;
    }
  }
  @include media-breakpoint-up(xsc){
    text-align: right;
    margin-top: 0;
    max-width: 200px;
    .logo-dipartimento {
      text-align: right;
      img {
        max-width: 150px;
        height: auto;
      }
    }
  }
}
.navbar-toggler {
  border: none;
  font-size: 1.5em;
  .desc {
    display: block;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 5px;
  }
}