.main-menu-nav {
  padding-top: 0;
  padding-bottom: 0;
  .menu {
    &--main {
      > li {
        .nav-link {
          padding-left: $navbar-nav-link-padding-x*2 !important;
          padding-right: $navbar-nav-link-padding-x*2 !important;
          @include media-breakpoint-up(md) {
            padding-top: 10px !important;
            padding-bottom: 10px !important;
          }
        }

        @include media-breakpoint-up(md) {
          &.dropdown {
            position: relative;
            > .dropdown-menu {
              left: 0;
              width: 300px;
              padding: 0;
              background-color: $white;
              > li {
                > a {
                  display: block;
                  font-size: 16px;
                  height: auto !important;
                  line-height: 24px !important;
                  padding: 8px 10px;
                  text-transform: none;
                  background: $gray-100;
                  &:hover {
                    text-decoration: none;
                  }
                }
                &.dropdown ul {
                  display: none;
                }
              }

            }
            &:hover {
              background: rgba($black, 0.2);

              > .dropdown-menu {
                display: block;
              }
            }
          }
          .menu-collapse-control {
            display: none;
          }
        }
        > ul {
          margin: 0;
          a {
            color: $black;
          }
        }
      }
    }
  }
}
.menu {
  &--language-switcher,
  &--top-header {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    li {
      text-align: center;
      padding: 0 10px;
      &:not(:first-child) {
        border-left: 1px solid $gray-100;
      }
    }
  }
  &--language-switcher {
    text-transform: uppercase;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        float: left;
        .language-link {
          color: $white;
        }
      }
    }
  }
}
.menu--top-header,
.menu--language-switcher,
.site-search {
  @include media-breakpoint-down(xs){
    padding-bottom: 5px;
  }
}

@include media-breakpoint-down(md){
  .navbar-offcanvas {
    background-color: $white;
    border-right: 0 solid $gray-300;
    bottom: 0;
    height: 100%;
    left: -100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 42px 0 0;
    position: fixed;
    top: 0;
    transition: all .3s ease-in-out 0s;
    max-width: 320px;
    z-index: 3000;
    .navbar-toggler {
      color: $primary;
      position: absolute;
      top: 10px;
      right: 0px;
      z-index: 3010;
    }
    .menu--main {
      li {
        &.active-trail {
          background-color: rgba($primary, 0.1);
        }
        a.nav-link {
          color: $dark !important;
          border-bottom: 1px solid $gray-300;
          padding: 6px $navbar-nav-link-padding-x*2;
          font-size: 18px;
        }
        &.dropdown {
          position: relative;
          .menu-collapse-control {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: $gray-500;
            text-align: center;
            -webkit-transition: -webkit-transform .2s ease-in-out;
            -ms-transition: -ms-transform .2s ease-in-out;
            transition: transform .2s ease-in-out;
            &[aria-expanded="true"] {
              transform: rotateX(180deg);
              -webkit-transition: -webkit-transform .2s ease-in-out;
              -ms-transition: -ms-transform .2s ease-in-out;
              transition: transform .2s ease-in-out;
            }
          }
        }
        ul {
          padding: 0;
          border: none;
          a {
            display: block;
            color: $dark !important;
            border-bottom: 1px solid $gray-300;
            padding:6px $navbar-nav-link-padding-x*2 6px $navbar-nav-link-padding-x*3;
            font-size: 17px;
          }
          ul a {
            padding-left: $navbar-nav-link-padding-x*4;
          }
        }
      }
    }
    &.show {
      left: 0;
    }
  }
}